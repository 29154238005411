<template lang="pug">
  .vx-card__title.mb-8
    div.flex.flex-wrap.justify-center
      h4.mb-4.Bienvenido {{ $t('seguimiento.welcome') }}
    div.flex.flex-wrap.justify-center
      p.Sigue-tus-envios-con-la-orden-de-transporte {{ $t('seguimiento.sigue') }}
    form.pt-3
      label(
        class="vs-select--label font-service"
      ) {{ $t('seguimiento.search') }}
      vs-input(
        class="w-full"
        v-validate="'required'"
        name="guia"
        v-model="code"
      )
      span.text-danger.text-sm(
        v-show="errors.has('guia')"
      ) {{ errors.first('guia') }}
    .flex.flex-wrap.justify-center
      vs-button.botones-search(
        :disabled="show_button"
        @click="setSave"
      ) {{ $t('seguimiento.search') }}
</template>
<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { siteKey: '6LfuYt8UAAAAAEV9NXghwVshCa2SYWMZ8kqVe79i' })
export default {
  data() {
    return {
      code: '',
      token: '',
      show_button: false,
      infoData: null
    }
  },
  mounted() {
    if (this.$route.query.hasOwnProperty('guia')) {
      this.code = this.$route.query.guia
      // this.$router.push({ name: 'searchSp', params: { code: this.code } })
      this.setSave()
    }
  },
  methods: {
    setSave() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.show_button = true
          this.token = this.$route.query.token
          this.$router.push({ path: '/seguimiento/', query: { 
            guia: this.code,
            token: this.token
          }})
        }
      })
    }
  },
}
</script>
<style>
.font-service {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c2c2c;
}
.vs-inputx {
  height: 50px;
  margin: 1px 0 0;
  padding: 16px 260px 15px 10px;
  border-radius: 6px;
  border: solid 1px #6f6f76 !important;
  background-color: #f0f0f0;
}
.Rectangle-3 {
  width: 348px;
  height: 50px;
  margin: 1px 0 0;
  padding: 16px 260px 15px 10px;
  border-radius: 6px;
  border: solid 1px #6f6f76;
  background-color: #f0f0f0;
}
.Sigue-tus-envios-con-la-orden-de-transporte {
  width: 250px;
  height: 43px;
  flex-grow: 0;
  margin: 21px 0px 12px 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.Bienvenido {
  width: 84px;
  height: 19px;
  flex-grow: 0;
  margin: 12.3px 131px 2px 135px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.botones-search {
  width: 100%;
  height: 56px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 39px 0 0 0px;
  padding: 10px 8px;
  border-radius: 6px;
  background-color: #fdd402;
  font-weight: 800;
}
</style>